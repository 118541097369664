import { Amplify, Auth } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
  }
})

async function checkLoggedIn () {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch (e) {
    return false
  }
}
async function getUserName () {
  try {
    const user = await Auth.currentUserInfo()
    return user.attributes.email
  } catch {
    return ''
  }
}

async function getIdToken () {
  await checkLoggedIn()
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

function logOut () {
  Auth.signOut()
}

async function assertUserIsSysAdmin () {
  const token = await getIdToken()
  const decodedToken = JSON.parse(atob(token.split('.')[1]))
  const groupMemberships = decodedToken['cognito:groups']
  return Array.isArray(groupMemberships) && groupMemberships.includes('system_user')
}

const amplify = {
  Amplify,
  Auth,
  checkLoggedIn,
  getUserName,
  getIdToken,
  logOut,
  assertUserIsSysAdmin
}

export default amplify
